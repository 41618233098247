import { DropdownButton, MenuItem } from 'react-bootstrap'
import { getSelectedSubArea, getDefaultBookingTypeForRepresentative } from './utils'

export const SubAreaDropdown = ({
    props, 
    disabled, 
    typeOfBooking, 
    setTypeOfBooking,
    setRepresentative,
    subArea,
    setSubArea,
    representative
}) => {

    const selectedSubArea = getSelectedSubArea(subArea?.id, props, representative)

    const title = selectedSubArea?.name || 'Valitse tulosalue...'

    const onSelect = subArea => {
        const representative = subArea.representatives[0]
        if ( representative ) {
            const defaultTypeOfBooking = getDefaultBookingTypeForRepresentative(representative, props, typeOfBooking)
            setTypeOfBooking(defaultTypeOfBooking)
        }
        setSubArea(subArea)
        setRepresentative(representative)
    }

    return <DropdownButton disabled={disabled} id="select-sub-area" title={title}>
        {props.subAreas.map(_subArea => 
            <MenuItem
                active={_subArea.id === selectedSubArea?.id}
                eventKey={_subArea}
                key={_subArea.id}
                onSelect={_subArea => onSelect(_subArea)}
            >
                <span>{_subArea?.name}</span>
            </MenuItem>
        )}
    </DropdownButton>

}