import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import { purgeStoredState, persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import storage from 'redux-persist/es/storage';
import createSagaMiddleware from 'redux-saga';
import moment from 'moment-timezone';
import { ErrorBoundary } from 'react-error-boundary'
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { connectRouter, ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { api } from './rtk/api';

import { ADMIN, REPRESENTATIVE, CLIENTADMIN } from './consts'

import { createBrowserHistory } from 'history';

import './i18n';
import './assets/stylesheets/app.css';

import { 
  WEBSOCKET_API_URL, 
  DEFAULT_ROUTES,
  REST_API_BASE_URL,
  COOKIE_CONSENT_V
} from './config';
import { reducers } from './reducers';
import { connect } from './redux/socket/actions';
import { initialize } from './redux/broadcast-channel/actions';
import { resetCall, setCallId } from './redux/call/actions';
import { addEntry as addHistoryEntry } from './redux/history/actions';
import sagas from './sagas';
import socketMiddleware from './socket';
import broadcastChannelMiddleware from './broadcast-channel';

import { ErrorBanner, TopBar, CookieConsent, Notification } from './components';

import withImportPrivateSagas from './with-import-private-sagas';

import {
  LoginView,
  ResetPasswordView,
  InitAccountView,
  ConnectOutlook,
  ConnectOutlookDone,
  LoginRepresentativeBooking,
  NotFoundView,
  MfaCodeView
} from './views/public'

import {
  BookingBooker,
  BookingView,
  CallView,
  CallNextView,
  ContactView,
  ContactsView,
  CopyBookingView,
  CopyBookingCalendarView,
  MoveBookingView,
  MoveBookingCalendarView,
  EditEventView,
  EventView,
  ReportsView,
  RepresentativeView,
  RescheduleView,
  SuggestionsView,
  ClientAdminView,
  ClientAdminsView,
  ClientAdminNewView,
  ProjectView,
  ProjectImportView,
  ProjectsView,
  ProjectCampaignsView,
  ProjectCampaignsNewView,
  ProjectNewView,
  ProjectExportView,
  CampaignView,
  RepresentativesView,
  RepresentativeNewView,
  BookersView,
  BookerNewView,
  RepresentativeEditView,
  BookerEditView,
  ClientView,
  ClientNewView,
  ClientsView,
  CalendarsView,
  RepresentativeCalendarView,
  ContactSuggestionsView,
  ContactNewView,
  RemoveContactsView,
  ProjectContactLogView,
  DateBlockView,
  RepresentativeBypassDateBlockView,
  SmsView,
  NoShowFiltersView,
  ConfirmationFiltersView,
  ProposedView,
  TermsOfUseView,
  AdminView,
  AdminNewView,
  AdminsView,
  BlocklistView,
  CallHistoryView,
  CallHistoryPopupView,
  ReassignView,
  PfizerView,
  PfizerSmsView,
  BackgroundProcessLogView,
  BusinessBoosterCallView,
  BusinessDbView,
  ApiAccessView,
  NotificationView,
  AdminPanelView
} from './views/private';

moment.tz.setDefault('Europe/Helsinki')

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const history = createBrowserHistory();

history.listen(e => {
  setTimeout(() => {
    store.dispatch(addHistoryEntry(e.pathname))
  }, 0)
})

const initialState = {};
const sagaMiddleware = createSagaMiddleware();

const config = {
  debug: false,
  key: 'root',
  storage,
  blacklist: ["router", "api"]
};

const createAppReducer = history => combineReducers({
  router: connectRouter(history),
  api: api.reducer,
  ...reducers
})

const appReducer = createAppReducer(history)

const deleteCookie = name => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

const rootReducer = persistReducer(config, ( state, action ) => {
  if ( action.type === 'CLEAR_STATE' ) {
    purgeStoredState(config)
    if ( action.payload && action.payload.close ) {
      window.close()
    }
    deleteCookie('token')
    if ( action.payload && action.payload.reload ) {
      window.location.reload()
    }
    if ( action.payload && action.payload.redirectToRoot ) {
      window.location = '/'
    }
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
})

// Store is imported in utils.saga.js to get user token
export const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false
  }).concat(
    routerMiddleware(history), 
    api.middleware,
    sagaMiddleware, 
    socketMiddleware, 
    broadcastChannelMiddleware
  )
});

store.dispatch(initialize())
const persistor = persistStore(store);
sagaMiddleware.run(sagas)

setTimeout(() => {
  store.dispatch(resetCall())
  store.dispatch(setCallId())
}, 0)

window.store = store
window.axiosCancelTokenSources = {}

let hasPrivateSagas = false

const DefaultRoute = ({ component: Component, ...rest }) => {
  const state = store.getState()
  const token = state.user.token; 
  if (token !== undefined && state.user.type && !state.user.min) {
    return <Redirect to={DEFAULT_ROUTES[state.user.type]} />;
  } else {
    return <Route {...rest} render={matchProps => {
      return <Component {...matchProps} />
    }} />
  }
}

const LogoutRoute = ({ ...attrs }) => {
  store.dispatch({type: 'CLEAR_STATE'})
  return <Redirect to="/" />;
}

const CustomUIPrivateRoute = ({ component: Component, ...rest }) => {
//  const state = store.getState()
//  if ( state.call.selectedProject && state.call.selectedProject.customUserInterfaceId) {
    return <PrivateRoute component={Component} {...rest} />
//  } else {
//    return <Redirect to="/" />;
//  }
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const state = store.getState()
  const token = state.user.token; 
  let decodedToken = null
  try { decodedToken = jwtDecode(token) } 
  catch ( e ) {}
  if ( token && decodedToken && decodedToken.scope !== 'pre-access' ) {
    if ( state.user.acceptedTermsOfUseAt == null && rest.path !== '/terms' ) {      
      return <Redirect to="/terms" />;
    }
    // Reconnect socket in each default layout
    const wsUrl = `${WEBSOCKET_API_URL}?Authorization=${token}`;
    store.dispatch(connect({ url: wsUrl }));
    return (
      <Route
        {...rest}
        render={matchProps => {
          let C = null
          if ( hasPrivateSagas ) {
            C = <Component {...matchProps} />
          } else {
            const WithImportPrivateSagas = withImportPrivateSagas(<Component {...matchProps} />, sagaMiddleware)
            C = <WithImportPrivateSagas />
          }
          hasPrivateSagas = true
          return (
            <div>
              <ErrorBanner />
              { ( !state.user.min && rest.min === undefined ) &&
                <TopBar
                  contactContext={ rest.contactContext }
                  callDisabled={ rest.callDisabled }
                />
              }
              { [ADMIN, REPRESENTATIVE, CLIENTADMIN].includes(state.user.type) &&
                <Notification includeRead={state.user.type === ADMIN} />
              }
              { C }
            </div>
          )
        }}
      />
    );
  } else {
    // User had not logged in
    if ( decodedToken?.scope === 'pre-access' ) {
      store.dispatch({type: 'CLEAR_STATE'})
    }
    return <Redirect to="/" />;
  }
};

const getMyInfoPath = () => {
  const user = store.getState().user
  if ( user.representative !== null ) {
    return `representatives/${user.representative.id}`
  } else if ( user.booker !== null ) {
    return `bookers/${user.booker.id}`
  } else if ( user.admin !== null ) {
    return `admins/${user.admin.id}`
  }
}

ReactDOM.render(
  <ErrorBoundary onError={(error, componentStack) => {
    const payload = {
      error: error ? error.toString() : error, 
      stack: error ? error.stack : null, 
      componentStack
    }
    const state = store.getState()
    if ( state && state.user ) {
      payload.user = {
        id: state.user.id,
        email: state.user.email,
        type: state.user.type
      }
    }
    axios.post(`${REST_API_BASE_URL}/log-client-error`, payload)
      .then(() => {
        return axios.get(`${REST_API_BASE_URL}/logout`).then(() => {
          store.dispatch({type: 'CLEAR_STATE', payload: {redirectToRoot: true}})
        })
      }).catch(err => {
        store.dispatch({type: 'CLEAR_STATE', payload: {redirectToRoot: true}})
      })
    return null
  }}>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <Suspense fallback={<div style={{ margin: 15 }}>Ladataan...</div>}>
          <Switch>
            <Route exact path="/me"
              render={props => { 
                return <Redirect to={getMyInfoPath()} />
              }}
            />
            <Route exact path="/code" component={MfaCodeView} />
            <LogoutRoute exact path="/logout" />
            <Route exact path="/login" component={LoginView} />
            <Route exact path="/login/:email" component={LoginView} />
            <DefaultRoute exact path="/" component={LoginView} />
            <Route exact path="/reset-password/:token" component={ResetPasswordView} />
            <Route exact path="/init-account/:token" component={InitAccountView} />
            <Route exact path="/init-account" component={InitAccountView} />
            <Route exact path="/connect-outlook/:token" component={ConnectOutlook} />
            <Route exact path="/connect-outlook" component={ConnectOutlookDone} />
            <Route exact path="/bookings/:id/token/:token" component={LoginRepresentativeBooking} />
            <Route exact path="/reset-password/:token" component={ResetPasswordView} />
            <Route exact path="/ui/1" render={props => <Redirect to={'/ui/1/contact'} />}/>
            <CustomUIPrivateRoute exact path="/ui/1/contact" component={PfizerView} />
            <CustomUIPrivateRoute exact path="/ui/1/contact/:contactId" component={PfizerView} />
            <CustomUIPrivateRoute exact path="/ui/1/sms" component={PfizerSmsView} />
            <Route exact path="/ui/2" render={props => <Redirect to={'/ui/2/call'} />}/>
            <CustomUIPrivateRoute exact 
              contactContext={ function(){ return this.props.contact }}
              path="/ui/2/call/:id" 
              component={BusinessBoosterCallView} />
            <CustomUIPrivateRoute exact 
              contactContext={ function(){ return this.props.contact }}
              path="/ui/2/call" 
              component={BusinessBoosterCallView} />
            <PrivateRoute exact path="/api-access" component={ApiAccessView} />
            <PrivateRoute exact path="/notification" component={NotificationView} />
            <PrivateRoute exact path="/call-history/:id" component={CallHistoryView} />
            <PrivateRoute exact path="/call-history" component={CallHistoryView} />
            <PrivateRoute exact path="/terms" component={TermsOfUseView} />
            <PrivateRoute min exact path="/sms" component={SmsView} />
            <PrivateRoute min path="/call-history-popup" component={CallHistoryPopupView} />
            <PrivateRoute min exact path="/contacts/:contactId/bookings/:bookingId/sms" component={SmsView} />
            <PrivateRoute exact path="/calendars" component={CalendarsView} />
            <PrivateRoute
              path="/representative-calendar/:representativeId/contacts/:contactId/bookings/:bookingId"
              component={BookingBooker}
            />
            <PrivateRoute exact path="/representative-calendar/:representativeId" component={RepresentativeCalendarView} />
            <PrivateRoute exact
              callDisabled={ function() { return false; /*this.props.call.finished*/ }}
              contactContext={ function(){ return this.props.contact }}
              path="/bookings/:id"
              component={BookingView}
            />
            <PrivateRoute exact
              callDisabled={ function() { return false; /*this.props.call.finished*/ }}
              contactContext={ function(){ return this.props.contact }}
              goBack={false}
              path="/bookings/:id/_"
              component={BookingView}
            />
            <PrivateRoute path="/bookings/:id/copy/calendar" component={CopyBookingCalendarView} />
            <PrivateRoute exact path="/bookings/:id/copy" component={CopyBookingView} />
            <PrivateRoute path="/bookings/:id/move/calendar" component={MoveBookingCalendarView} />
            <PrivateRoute exact path="/bookings/:id/move" component={MoveBookingView} />
            <PrivateRoute exact
              callDisabled={ function() { return false; /*this.props.call.finished*/ }}
              contactContext={ function(){ return this.props.contact }}
              path="/call"
              component={CallView}
            />
            <PrivateRoute exact path="/call/next" component={CallNextView} />
            <PrivateRoute exact path="/admin-panel" component={AdminPanelView} />
            <PrivateRoute
              callDisabled={ function() { return false; /*this.props.call.finished*/ }}
              contactContext={ function(){ return this.props.contact }}
              path="/call/suggestions"
              component={SuggestionsView}
            />
            <PrivateRoute path="/call/contacts" component={ContactsView} />
            <PrivateRoute 
              path="/call/noshow-filters" 
              callDisabled={ function() { return false; /*this.props.call.finished*/ }}
              contactContext={ function(){ return this.props.contact }}          
              component={NoShowFiltersView} 
            />
            <PrivateRoute 
              path="/call/confirmation-filters" 
              callDisabled={ function() { return false; /*this.props.call.finished*/ }}
              contactContext={ function(){ return this.props.contact }}          
              component={ConfirmationFiltersView} 
            />
            <PrivateRoute
              exact
              path="/contacts/new"
              component={ContactNewView}
            />
            <PrivateRoute exact
              contactContext={ function(){ return this.props.contact }}
              path="/contacts/:id/reserve"
              component={ContactView}
            />
            <PrivateRoute exact
              contactContext={ function(){ return this.props.contact }}
              path="/contacts/:id"
              component={ContactView}
            />
            <PrivateRoute exact
              contactContext={ function(){ return this.props.contact }}
              path="/contacts/:id/suggestions"
              component={ContactSuggestionsView}
            />
            <PrivateRoute
              contactContext={ function(){ return this.props.contact }}
              path="/contacts/:contactId/bookings/:bookingId/:state"
              component={BookingBooker}
            />
            <PrivateRoute
              contactContext={ function(){ return this.props.contact }}
              path="/contacts/:contactId/bookings/:bookingId"
              component={BookingBooker}
            />
            <PrivateRoute exact path="/representative-calendar/:representativeId/events/:id" component={EventView} />
            <PrivateRoute
              exact
              path="/representative-calendar/:representativeId/events/:id/edit"
              component={EditEventView}
            />
            <PrivateRoute exact path="/representative-calendar/:representativeId/new-event" component={EditEventView} />
            <PrivateRoute
              exact
              path="/representative-calendar/:representativeId/reschedule/:id"
              component={RescheduleView}
            />
            <PrivateRoute
              exact
              path="/representative-calendar/:representativeId/reschedule/:id/:mode"
              component={RescheduleView}
            />
            <PrivateRoute exact path="/events/:id" component={EventView} />
            <PrivateRoute
              exact
              path="/events/:id/edit"
              component={EditEventView}
            />
            <PrivateRoute path="/new-event" component={EditEventView} />
            <PrivateRoute
              exact
              path="/representative"
              component={RepresentativeView}
            />
            <PrivateRoute
              exact
              path="/proposed"
              component={ProposedView}
            />
            <PrivateRoute path="/reports" component={ReportsView} />
            <PrivateRoute
              exact
              contactContext={ function(){ return this.props.contact }}
              path="/reschedule/:id"
              component={RescheduleView}
            />
            <PrivateRoute
              exact
              contactContext={ function(){ return this.props.contact }}
              path="/reschedule/:id/:mode"
              component={RescheduleView}
            />
            <PrivateRoute
              exact
              path="/projects/new"
              component={ProjectNewView}
            />
            <PrivateRoute 
              exact 
              path="/projects/:id/reassign" 
              component={ReassignView} 
            />
            <PrivateRoute
              exact
              path="/projects/:id"
              component={ProjectView}
            />
            <PrivateRoute
              exact
              path="/projects/:id/contact-log"
              component={ProjectContactLogView}
            />
            <PrivateRoute
              exact
              path="/projects/:id/campaigns"
              component={ProjectCampaignsView}
            />
            <PrivateRoute
              exact
              path="/projects/:id/campaigns/new"
              component={ProjectCampaignsNewView}
            />
            <PrivateRoute
              exact
              path="/projects/:id/import"
              component={ProjectImportView}
            />
            <PrivateRoute
              exact
              path="/projects/:id/export"
              component={ProjectExportView}
            />
            <PrivateRoute
              exact
              path="/projects/:id/remove-contacts"
              component={RemoveContactsView}
            />
            <PrivateRoute
              exact
              path="/projects/:id/blocklist"
              component={BlocklistView}
            />
            <PrivateRoute
              exact
              path="/business-db"
              component={BusinessDbView}
            />
            <PrivateRoute
              exact
              path="/projects"
              component={ProjectsView}
            />
            <PrivateRoute
              exact
              path="/campaigns/:id"
              component={CampaignView}
            />
            <PrivateRoute
              exact
              path="/representatives/new"
              component={RepresentativeNewView}
            />
            <PrivateRoute 
              exact 
              path="/representatives/:id/date-blocks" 
              component={RepresentativeBypassDateBlockView} 
            />
            <PrivateRoute
              exact
              path="/representatives/:id"
              component={RepresentativeEditView}
            />
            <PrivateRoute
              exact
              path="/representatives"
              component={RepresentativesView}
            />
            <PrivateRoute
              exact
              path="/bookers/new"
              component={BookerNewView}
            />
            <PrivateRoute
              exact
              path="/bookers/:id"
              component={BookerEditView}
            />
            <PrivateRoute
              exact
              path="/bookers"
              component={BookersView}
            />
            <PrivateRoute
              exact
              path="/admins/new"
              component={AdminNewView}
            />
            <PrivateRoute
              exact
              path="/background-process-log/:uuid"
              component={BackgroundProcessLogView}
            />
            <PrivateRoute
              exact
              path="/admins"
              component={AdminsView}
            />
            <PrivateRoute
              exact
              path="/admins/:id"
              component={AdminView}
            />
            <PrivateRoute
              exact
              path="/clients"
              component={ClientsView}
            />
            <PrivateRoute
              exact
              path="/clients/new"
              component={ClientNewView}
            />
            <PrivateRoute
              exact
              path="/clients/:id"
              component={ClientView}
            />
            <PrivateRoute
              exact
              path="/clients/:id/projects"
              component={ProjectsView}
            />
            <PrivateRoute
              exact
              path="/clients/:id/projects/new"
              component={ProjectNewView}
            />
            <PrivateRoute
              exact
              path="/clients/:id/representatives"
              component={RepresentativesView}
            />
            <PrivateRoute
              exact
              path="/clients/:id/representatives/new"
              component={RepresentativeNewView}
            />
            <PrivateRoute
              exact
              path="/client-admins/:id"
              component={ClientAdminView}
            />
            <PrivateRoute
              exact
              path="/clients/:id/client-admins/new"
              component={ClientAdminNewView}
            />
            <PrivateRoute
              exact
              path="/clients/:id/client-admins"
              component={ClientAdminsView}
            />
            <PrivateRoute
              exact
              path="/blocklist"
              component={BlocklistView}
            />
            <PrivateRoute exact path="/date-blocks" component={DateBlockView} />
            <Route path="/404" component={NotFoundView} />
            <Redirect to="/404" />
          </Switch>
          {COOKIE_CONSENT_V ? <CookieConsent/> : null }
        </Suspense>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
