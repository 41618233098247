import moment from 'moment'
import { useState } from 'react'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { 
    getAdjacentRepresentativesByRepresentativeId, 
    getRepresentativeName, 
    getDefaultBookingTypeForRepresentative 
} from './utils'
import { getServicePoint } from './utils'
import { useLazyGetStatisticsForServicePointQuery } from '../../rtk/statistics-api'

export const RepresentativeDropdown = ({
    props, 
    disabled,
    callbackStartsAt, 
    typeOfBooking, 
    setTypeOfBooking,
    representative,
    setRepresentative,
    subArea
}) => {

    const [ hasMaxBookings, setHasMaxBookings ] = useState({})

    const [getStatisticsForServicePoint, { isLoading, isFetching }] = useLazyGetStatisticsForServicePointQuery()

    const updateMaxBookingCount = async () => {

        const date = moment(callbackStartsAt)
        if ( !date ) return

        const projectId = getServicePoint(props).projectId
        let servicePointId = representative
          ? representative.servicePoint?.id
          : props.servicePoint.id
    
        if ( !servicePointId ) {
          const servicePoint = getServicePoint(props)
          servicePointId = servicePoint.id
        }

        const params = {
          date: date.toISOString(),
          endDate: date.toISOString(),
          timezone: 'Europe/Helsinki',
          projectId
        }

        try {
            const stats = await getStatisticsForServicePoint({servicePointId, params}).unwrap()
            const hasMaxBookings = {}
            stats.forEach(a => {
              if ( a.n[0].count >= a.maxBookingCount ) {
                hasMaxBookings[a.representativeId] = true
              }
            })
            setHasMaxBookings(hasMaxBookings)
        } catch ( e ) {
            console.error(e)
            setHasMaxBookings({})
        }

    }    

    const title = representative
        ? getRepresentativeName(representative)
        : 'Valitse edustaja...'

    const onToggle = isOpen => {
        if ( isOpen ) {
            if ( props.contact.project.businessCheckBookingValidity && !props.bookingId ) {
                updateMaxBookingCount()
            }
        }
    }

    const onSelect = representative => {
        setTypeOfBooking(
            getDefaultBookingTypeForRepresentative(
                representative, 
                props, 
                typeOfBooking
            )
        )
        setRepresentative(representative)
    }

    let representatives = []
    if ( !(isLoading || isFetching) ) {
        representatives = props.project.mergeServicePoints
            ? (subArea?.representatives || [])
            : (representative 
                ? getAdjacentRepresentativesByRepresentativeId(representative.id, props)
                : props.servicePoint.representatives || getServicePoint(props).representatives || []
            )
    }
    
    return <DropdownButton
        disabled={disabled}
        onToggle={isOpen => onToggle(isOpen)}
        id="select-representative"
        title={title}
    >
        {(isLoading || isFetching) &&
            <RepresentativeDropdownSpinner />
        }
        {representatives.map(_representative => 
            <MenuItem
                active={_representative.id === representative?.id}
                eventKey={_representative}
                key={_representative.id}
                onSelect={_representative => onSelect(_representative)}
            >
                <span style={{ 
                    color: !props.bookingId && 
                        hasMaxBookings[_representative.id] 
                            ? 'red' : 'unset' 
                }}>{getRepresentativeName(_representative)}</span>
                </MenuItem>
        )}
    </DropdownButton>

}

const RepresentativeDropdownSpinner = () => {
    return <div style={{ height: 75 }} className="busy-indicator">
        <div className="spinner" 
            style={{
                maxHeight: 50,
                maxWidth: 50,
                borderWidth: 10
            }}
        />
    </div>
}
